<script>
import Layout from "../../layouts/main";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {};
  },
  computed: {
    results() {
      return this.$store ? this.$store.state.results.Results : null;
    },
  },
};
</script>

<template>
  <layout>
    <b-col>
      <b-row v-for="res in results" :key="res.id">
        <b-col>
          <b-card
            style="background-color:darkred;color:white;border-radius:15px;"
          >
            <b-col>
              <b-row>
                <b-col>
                  <h5 style="color:white;">{{ res.examtitle }}</h5>
                </b-col>
              
                <b-col>
                  <h5  style="color:white;">{{ res.examdate }}</h5>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <h5  style="color:white;">Total {{ res.total }}</h5>
                </b-col>
              
                <b-col>
                  <h5  style="color:white;">Level {{ res.level }}</h5>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <hr style="background-color:white;color:white;height:2px;">
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <h5 style="color:white;" >Comprehension</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p> Reading</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.compreading }} / {{ res.barcompreading }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <p>Listening</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.complistening }} / {{ res.barcomplistening }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <h5 style="color:white;" >Production</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>Speaking</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.prodspeaking }} / {{ res.barprodspeaking }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <p>Writing</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.prodwriting }} / {{ res.barprodwriting }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <hr style="background-color:white;color:white;height:2px;">
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <p>Reading Comprehension</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.readingcomp }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <p>Written Production</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.writtenprod }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <p>Oral Comprehension</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.oralcomp }}</p>
                </b-col>               
              </b-row>
              <b-row>
                <b-col>
                  <p>Oral Production</p>
                </b-col>
              
                <b-col>
                  <p>{{ res.oralprod }}</p>
                </b-col>               
              </b-row>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </layout>
</template>
